import {
  GET_ADMIN_COMPANY_DATA,
  GET_COMPANY_TEMPLATE,
  GETING_COMPANY_FOR_ADMIN,
  GETTING_ADMINS,
} from "../constants/Constants";

export const CompanyForAdminReducerList = (
  state = { companiesAdmin: [] },
  action
) => {
  switch (action.type) {
    case GETING_COMPANY_FOR_ADMIN:
      return { companiesAdmin: action.payload };
    default:
      return state;
  }
};

const initialState = {
  adminCompany: {},
  companyTemplate: [],
};

export const adminCompanyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMIN_COMPANY_DATA:
      return { ...state, adminCompany: action.payload };
    case GET_COMPANY_TEMPLATE:
      return { ...state, companyTemplate: action.payload };

    default:
      return state;
  }
};

export const AdminCompanyReducer = (state = { admins: [] }, action) => {
  switch (action.type) {
    case GETTING_ADMINS:
      return { admins: action.payload };
    default:
      return state;
  }
};
