import {
  GET_VEHICLES,
  GET_CHECKLIST,
  GET_VIN_DETAIL,
  // GET_VIN_DETAIL_FAIL,
  SET_VEHICLE_DETAIL,
  SET_VEHICLE_FILES,
  SET_VEHICLE_FILE,
  SET_VEHICLE_FILES_CATOGORIES,
  Annotated,
  FETCH_VEHICLE_INSPECTION_DATA_SUCCESS,
  UPDATE_VEHICLE_MILAGE_DATA_SUCCESS,
  GET_DASHBOARD_STATIONS,
} from "../constants/Constants";

const vehicleFilesCatogories = [];

export const vehicleFiles = [
  {
    category: "license_plate_number",
    title: "License Plate ",
    url: "",
    extension: "image/png",
    orientation: "",
    groupType: "carVerificiationItems",
    loading: false,
    fileId: "",
    dateImage: null,
    status: null,
    statusAi: null,
    commentAi: null,
    comments: null,
    inspectionId: null,
    InspectionChecks: [],
    type: "image",
    createdAt: "",
  },
  {
    category: "odometer",
    title: "Odometer",
    extension: "image/png",
    orientation: "",
    groupType: "carVerificiationItems",
    url: "",
    fileId: "",
    dateImage: null,
    status: null,
    inspectionId: null,
    type: "image",
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
    createdAt: "",
  },
  {
    category: "inside_cargo_roof",
    title: "Inside Cargo Roof",
    url: "",
    extension: "image/png",
    orientation: "",
    groupType: "exteriorItems",
    fileId: "",
    type: "image",
    dateImage: null,
    status: null,
    inspectionId: null,
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
    aiurl: "",
    coordinateArray: [],
    llamaMessage: "",
    llamaCost: "",
    createdAt: "",
  },
  {
    //for video of avadar
    category: "exterior_left",
    title: "Exterior Left",
    url: "",
    extension: "image/png",
    orientation: "",
    groupType: "exteriorItems",
    fileId: "",
    type: "image",
    dateImage: null,
    status: null,
    inspectionId: null,
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
    aiurl: "",
    coordinateArray: [],
    llamaMessage: "",
    llamaCost: "",
    createdAt: "",
  },
  {
    //for video of avadar
    category: "exterior_right",
    title: "Exterior Right",
    url: "",
    extension: "image/png",
    orientation: "",
    groupType: "exteriorItems",
    fileId: "",
    type: "image",
    dateImage: null,
    status: null,
    inspectionId: null,
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
    aiurl: "",
    coordinateArray: [],
    llamaMessage: "",
    llamaCost: "",
    createdAt: "",
  },

  {
    category: "front_left_corner",
    title: "Front Left Corner",
    url: "",
    extension: "image/png",
    orientation: "",
    groupType: "exteriorItems",
    fileId: "",
    type: "image",
    dateImage: null,
    status: null,
    inspectionId: null,
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
    aiurl: "",
    coordinateArray: [],
    llamaMessage: "",
    llamaCost: "",
    createdAt: "",
  },
  {
    category: "front_right_corner",
    title: "Front Right Corner",
    url: "",
    extension: "image/png",
    orientation: "",
    groupType: "exteriorItems",
    fileId: "",
    type: "image",
    dateImage: null,
    status: null,
    inspectionId: null,
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
    aiurl: "",
    coordinateArray: [],
    llamaMessage: "",
    llamaCost: "",
    createdAt: "",
  },
  {
    category: "rear_left_corner",
    title: "Rear Left Corner",
    url: "",
    extension: "image/png",
    orientation: "",
    groupType: "exteriorItems",
    fileId: "",
    type: "image",
    dateImage: null,
    status: null,
    inspectionId: null,
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
    aiurl: "",
    coordinateArray: [],
    llamaMessage: "",
    llamaCost: "",
    createdAt: "",
  },
  {
    category: "rear_right_corner",
    title: "Rear Right Corner",
    url: "",
    extension: "image/png",
    orientation: "",
    groupType: "exteriorItems",
    fileId: "",
    type: "image",
    dateImage: null,
    status: null,
    inspectionId: null,
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
    aiurl: "",
    coordinateArray: [],
    llamaMessage: "",
    llamaCost: "",
    createdAt: "",
  },

  // {
  //   //for video of avadar
  //   category: "exterior_front",
  //   title: "Exterior Front",
  //   url: "",
  //   extension: "video/mp4",
  //   groupType: "exteriorItems",
  //   fileId: "",
  //   type: "video",
  //   dateImage: null,
  //   status: null,
  //   inspectionId: null,
  //   loading: false,
  //   statusAi: null,
  //   comments: null,
  //   commentAi: null,
  //   InspectionChecks: [],
  //   aiurl: "",
  //   coordinateArray: [],
  //   llamaMessage: "",
  //   llamaCost: "",
  // },
  // {
  //   //for video of avadar
  //   category: "exterior_rear",
  //   title: "Exterior Rear",
  //   url: "",
  //   extension: "video/mp4",
  //   groupType: "exteriorItems",
  //   fileId: "",
  //   type: "video",
  //   dateImage: null,
  //   status: null,
  //   inspectionId: null,
  //   loading: false,
  //   statusAi: null,
  //   commentAi: null,
  //   comments: null,
  //   InspectionChecks: [],
  //   aiurl: "",
  // },
  {
    //for video of avadar
    category: "exterior_front",
    title: "Exterior Front",
    url: "",
    extension: ".jpg",
    groupType: "exteriorItems",
    fileId: "",
    type: "image",
    dateImage: null,
    status: null,
    inspectionId: null,
    loading: false,
    statusAi: null,
    comments: null,
    commentAi: null,
    InspectionChecks: [],
    aiurl: "",
    coordinateArray: [],
    llamaMessage: "",
    llamaCost: "",
    createdAt: "",
  },
  {
    //for video of avadar
    category: "exterior_rear",
    title: "Exterior Rear",
    url: "",
    extension: ".jpg",
    groupType: "exteriorItems",
    fileId: "",
    type: "image",
    dateImage: null,
    status: null,
    inspectionId: null,
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
    aiurl: "",
    createdAt: "",
  },
  {
    category: "left_front_tire",
    title: "Left Front Tire",
    url: "",
    extension: "image/png",
    orientation: "",
    groupType: "tires",
    fileId: "",
    dimension: "",
    dateImage: null,
    status: null,
    inspectionId: null,
    type: "image",
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
    createdAt: "",
  },
  {
    category: "left_rear_tire",
    title: "Left Rear Tire",
    url: "",
    extension: "image/png",
    orientation: "",
    groupType: "tires",
    dimension: "",
    fileId: "",
    type: "image",
    dateImage: null,
    status: null,
    inspectionId: null,
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
    createdAt: "",
  },
  {
    category: "right_front_tire",
    title: "Right Front Tire",
    url: "",
    extension: "image/png",
    orientation: "",
    groupType: "tires",
    dimension: "",
    fileId: "",
    type: "image",
    dateImage: null,
    status: null,
    inspectionId: null,
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
    createdAt: "",
  },
  {
    category: "right_rear_tire",
    title: "Right Rear Tire",
    url: "",
    extension: "image/png",
    orientation: "",
    groupType: "tires",
    dimension: "",
    fileId: "",
    type: "image",
    dateImage: null,
    status: null,
    inspectionId: null,
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
    createdAt: "",
  },
];
const vehicleFile = [
  {
    category: "license_plate_number",
    title: "License Plate",
    url: "",
    extension: "image/png",
    orientation: "",
    groupType: "carVerificiationItems",
    loading: false,
    fileId: "",
    dateImage: null,
    status: null,
    statusAi: null,
    commentAi: null,
    comments: null,
    inspectionId: null,
    InspectionChecks: [],
    type: "image",
  },
  {
    category: "odometer",
    title: "Odometer",
    extension: "image/png",
    orientation: "",
    groupType: "carVerificiationItems",
    url: "",
    fileId: "",
    dateImage: null,
    status: null,
    inspectionId: null,
    type: "image",
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
  },
  {
    category: "exterior_left",
    title: "Exterior Left",
    url: "",
    extension: "image/png",
    orientation: "",
    groupType: "exteriorItems",
    fileId: "",
    type: "image",
    dateImage: null,
    status: null,
    inspectionId: null,
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
    aiurl: "",
    coordinateArray: [],
    llamaMessage: "",
    llamaCost: "",
  },
  {
    category: "exterior_right",
    title: "Exterior Right",
    url: "",
    extension: "image/png",
    orientation: "",
    groupType: "exteriorItems",
    fileId: "",
    type: "image",
    dateImage: null,
    status: null,
    inspectionId: null,
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
    aiurl: "",
    coordinateArray: [],
    llamaMessage: "",
    llamaCost: "",
  },
  {
    category: "exterior_front",
    title: "Exterior Front",
    url: "",
    extension: "video/mp4",
    groupType: "exteriorItems",
    fileId: "",
    type: "video",
    dateImage: null,
    status: null,
    inspectionId: null,
    loading: false,
    statusAi: null,
    comments: null,
    commentAi: null,
    InspectionChecks: [],
    aiurl: "",
  },
  {
    category: "exterior_rear",
    title: "Exterior Rear",
    url: "",
    extension: "video/mp4",
    groupType: "exteriorItems",
    fileId: "",
    type: "video",
    dateImage: null,
    status: null,
    inspectionId: null,
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
    aiurl: "",
  },
  {
    category: "tire",
    title: "Tire",
    url: "",
    extension: "video/mp4",
    orientation: "",
    groupType: "tire",
    dimension: "",
    fileId: "",
    type: "video",
    dateImage: null,
    status: null,
    inspectionId: null,
    loading: false,
    statusAi: null,
    commentAi: null,
    comments: null,
    InspectionChecks: [],
  },
];

export const VehicleReducer = (
  state = {
    vehicles: [],
    vehicleDetail: [],
    vehicleFiles,
    vehicleFile,
    vehicleFilesCatogories,
    annotationPictureUrl: "",
    vehicleInspectionData: null,
    vehicleMilageData: null,
    dashboardStations: {},
  },
  action
) => {
  switch (action.type) {
    case GET_VEHICLES:
      return { ...state, vehicles: action.payload };
    // case GET_CHECKLIST:
    //   return { ...state, vehicles:action.payload };
    case SET_VEHICLE_FILES_CATOGORIES: // this is for webhook
      return { ...state, vehicleFilesCatogories: action.payload };
    case SET_VEHICLE_DETAIL:
      return { ...state, vehicleDetail: action.payload };
    case FETCH_VEHICLE_INSPECTION_DATA_SUCCESS:
      return {
        ...state,
        vehicleInspectionData: action.payload,
      };
    case UPDATE_VEHICLE_MILAGE_DATA_SUCCESS:
      return { ...state, vehicleMilageData: action.payload };
    case SET_VEHICLE_FILES:
      // action?.payload?.forEach((item) => {
      //   var a = state?.vehicleFiles?.find((a) => a.category === item.category);

      //   if (a) {
      //     if (
      //       a.groupType === "exteriorItems" &&
      //       item.groupType === "exteriorItems" &&
      //       item.pictureTag === "after"
      //     ) {
      //       a.aiurl = item.url;
      //       a.commentAi = item.commentAi;
      //       a.coordinateArray = item.coordinateArray;
      //       a.llamaMessage = item.llamaMessage;
      //       a.llamaCost = item.llamaCost;
      //       a.id = item.id;
      //       a.createdAt = item.createdAt;
      //     } else {
      //       a.url = item.url;
      //       a.InspectionChecks = item.InspectionChecks;
      //       a.coordinateArray = item.coordinateArray;
      //       a.llamaMessage = item.llamaMessage;
      //       a.llamaCost = item.llamaCost;
      //       a.status = item?.isReviewed;
      //       a.dateImage = item?.dateImage;
      //       a.orientation = item?.orientation;
      //       a.fileId = item?.id;
      //       a.inspectionId = item?.inspectionId;
      //       a.statusAi = item?.statusAi;
      //       a.comments = item?.comments;
      //       a.commentAi = item?.commentAi;
      //       a.createdAt = item.createdAt;
      //       //This below code set the type by the extension he get from the file detail api
      //       //and set extension he get from the api
      //       a.type =
      //         item?.extension === ".mp4" || item?.extension === "video/mp4"
      //           ? "video"
      //           : "image";
      //       a.extension = item?.extension;

      //       if (a.groupType === "tires") {
      //         a.dimension = item?.dimension;
      //         a.longitude = item?.longitude;
      //         a.latitude = item?.latitude;
      //       }
      //       if (a.groupType === "exteriorItems") {
      //         a.aiurl = "";
      //       }
      //       if (
      //         a.groupType === "exteriorItems" &&
      //         item.groupType === "exteriorItems" &&
      //         item.pictureTag === "after"
      //       ) {
      //         // a.extension = item?.extension;
      //       }
      //     }
      //   }
      // });
      // state.vehicleFiles.forEach((item) => {
      //   if (
      //     !action?.payload?.some(
      //       (payloadItem) => payloadItem.category === item.category
      //     )
      //   ) {
      //     item.url = "";
      //     item.InspectionChecks = [];
      //     item.coordinateArray = [];
      //     item.llamaMessage = "";
      //     item.llamaCost = "";
      //     item.status = null;
      //     item.dateImage = "";
      //     item.orientation = "";
      //     item.statusAi = null;
      //     item.comments = null;
      //     item.commentAi = null;
      //     item.fileId = "";
      //     item.inspectionId = null;
      //     item.createdAt = "";
      //     if (item.groupType === "tires") {
      //       item.dimension = "";
      //       item.longitude = "";
      //       item.latitude = "";
      //       item.createdAt = "";
      //     }
      //   }
      // });

      return { ...state, vehicleFiles: action.payload };

    case SET_VEHICLE_FILE:
      action?.payload?.forEach((item) => {
        const a = state?.vehicleFile?.find((a) => a.category === item.category);
        if (a) {
          if (
            item.groupType === "exteriorItems" &&
            item.pictureTag === "after"
          ) {
            a.aiurl = item.url;
            a.commentAi = item.commentAi;
            a.coordinateArray = item.coordinateArray;
            a.llamaMessage = item.llamaMessage;
            a.llamaCost = item.llamaCost;
            a.id = item.id;
          } else {
            a.url = item.url;
            a.InspectionChecks = item.InspectionChecks;
            a.coordinateArray = item.coordinateArray;
            a.llamaMessage = item.llamaMessage;
            a.llamaCost = item.llamaCost;
            a.status = item?.isReviewed;
            a.dateImage = item?.dateImage;
            a.orientation = item?.orientation;
            a.fileId = item?.id;
            a.inspectionId = item?.inspectionId;
            a.statusAi = item?.statusAi;
            a.comments = item?.comments;
            a.commentAi = item?.commentAi;
            if (a.groupType === "tire") {
              a.dimension = item?.dimension;
            }
            if (a.groupType === "exteriorItems") {
              a.aiurl = "";
            }
          }
        }
      });
      state.vehicleFile.forEach((item) => {
        if (
          !action?.payload?.some(
            (payloadItem) => payloadItem.category === item.category
          )
        ) {
          item.url = "";
          item.InspectionChecks = [];
          item.coordinateArray = [];
          item.llamaMessage = "";
          item.llamaCost = "";
          item.status = null;
          item.dateImage = "";
          item.orientation = "";
          item.statusAi = null;
          item.comments = null;
          item.commentAi = null;
          item.fileId = "";
          item.inspectionId = null;
          if (item.groupType === "tire") {
            item.dimension = "";
          }
        }
      });
    case Annotated:
      return { ...state, annotationPictureUrl: action.payload };
    case GET_DASHBOARD_STATIONS:
      return { ...state, dashboardStations: action.payload };
    default:
      return state;
  }
};

const transformVinDetails = (data) => {
  const transformationObject = {};
  data.forEach((obj) => {
    if (obj.label === "VIN") {
      transformationObject.vin = obj.value;
    }
    if (obj.label === "Make") {
      transformationObject.make = obj.value;
    }
    if (obj.label === "Model Year") {
      transformationObject.year = obj.value;
    }
    if (obj.label === "Model") {
      transformationObject.model = obj.value;
    }
  });
  return transformationObject;
};

export const VinReducer = (state = { vehicleVin: {} }, action) => {
  switch (action.type) {
    case GET_VIN_DETAIL:
      return { vehicleVin: transformVinDetails(action.payload) };
    default:
      return state;
  }
};

// export const annotationPictureUrlReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case SET_Annotation_Body:
//       return { ...state, body: action.payload };
//     default:
//       return state;
//   }
// };
