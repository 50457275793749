import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import { composeWithDevTools } from "redux-devtools-extension";
import { AuthReducer } from "./redux/reducers/Auth";
import { VehicleReducer, VinReducer } from "./redux/reducers/vehicle";
import {
  AdminReducer,
  CompanyForAdminReducer,
  AssigneesListReducer,
} from "./redux/reducers/admin";
import { SurveyReducer } from "./redux/reducers/survey";
import { CompanyReducer, TemplateReducer } from "./redux/reducers/company";
import { ProfileReducer } from "./redux/reducers/profile";
import { usersReducer } from "./redux/reducers/user";
import { adminVehicleReducer } from "./redux/reducers/adminVehicle";
import { driversReducer } from "./redux/reducers/drivers";
import {
  adminCompanyReducer,
  CompanyForAdminReducerList,
  AdminCompanyReducer,
} from "./redux/reducers/adminCompany";
import { CheckListReducer } from "./redux/reducers/CheckList";
import analyticsReducer from "./redux/reducers/analytic";
import { BlogReducer } from "./redux/reducers/blog";

const middleware = [thunk];
const initialState = {
  sidebarShow: "responsive",
  asideShow: false,
  darkMode: false,
};
const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    default:
      return state;
  }
};
const reducer = combineReducers({
  nav: changeState,
  auth: AuthReducer,
  getVehicle: VehicleReducer,
  getData: CheckListReducer,
  getAdmin: AdminReducer,
  getingAdminList: AdminCompanyReducer,
  getSurvey: SurveyReducer,
  getCompany: CompanyReducer,
  getTemplate: TemplateReducer,
  getCompanyForAdmin: CompanyForAdminReducer,
  getCompanyForAdminList: CompanyForAdminReducerList,
  getAssignees: AssigneesListReducer,
  getProfile: ProfileReducer,
  getUsers: usersReducer,
  getAdminVehicles: adminVehicleReducer,
  getDrivers: driversReducer,
  analytics: analyticsReducer,
  getVinVehicle: VinReducer,
  getBlogdata: BlogReducer,
  getAdminCompany: adminCompanyReducer,
});

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
